
import LogoText from '../../public/assets/images/logo-text.png';
import logo from '../../public/assets/images/logo.png';
import Loading from '../../public/assets/images/loading.gif';
import bck from '../../public/assets/images/bck.png';
import origin from '../../public/assets/images/origin.png';
import atom from '../../public/assets/images/atom.png';

import cap_part from '../../public/assets/images/body_parts/cap_part.png';
import top_part from '../../public/assets/images/body_parts/top_part.png';
import middle_part from '../../public/assets/images/body_parts/middle_part.png';
import bottom_part from '../../public/assets/images/body_parts/bottom_part.png';
import stand_part from '../../public/assets/images/body_parts/stand_part.png';


import cap_part_active from '../../public/assets/images/body_parts/cap_part-active.png';
import top_part_active from '../../public/assets/images/body_parts/top_part-active.png';
import middle_part_active from '../../public/assets/images/body_parts/middle_part-active.png';
import bottom_part_active from '../../public/assets/images/body_parts/bottom_part-active.png';
import stand_part_active from '../../public/assets/images/body_parts/stand_part-active.png';

import logo_circle from '../../public/assets/images/logo_circle.png';
import arIcon from '../../public/assets/images/ar-icon.png';

import White from '../../public/assets/images/colors/White.png';
import Black from '../../public/assets/images/colors/Black.png';
import amphibian_green  from '../../public/assets/images/colors/Amphibian green.png';
import columbus_blue  from '../../public/assets/images/colors/Columbus blue.png';
import izamal_yellow  from '../../public/assets/images/colors/izamal yellow.png';
import lobelia_blue  from '../../public/assets/images/colors/Lobelia blue.png';
import mithril_grey  from '../../public/assets/images/colors/mithril grey.png';
import opulent_brown  from '../../public/assets/images/colors/opulent brown.png';
import persian_red  from '../../public/assets/images/colors/persian red.png';
import prometheus_orange  from '../../public/assets/images/colors/Prometheus orange.png';
import shojohi_red  from '../../public/assets/images/colors/shojohi red.png';
import spanish_white  from '../../public/assets/images/colors/spanish white.png';







import atom_cap_part from '../../public/assets/images/atom body_parts/atom_cap_part.png';
import atom_top_part from '../../public/assets/images/atom body_parts/atom_top_part.png';
import atom_middle_part from '../../public/assets/images/atom body_parts/atom_middle_part.png';
import atom_bottom_part from '../../public/assets/images/atom body_parts/atom_bottom_part.png';
import atom_stand_part from '../../public/assets/images/atom body_parts/atom_stand_part.png';

import atom_cap_part_active from '../../public/assets/images/atom body_parts/atom_cap_part-active.png';
import atom_top_part_active from '../../public/assets/images/atom body_parts/atom_top_part-active.png';
import atom_middle_part_active from '../../public/assets/images/atom body_parts/atom_middle_part-active.png';
import atom_bottom_part_active from '../../public/assets/images/atom body_parts/atom_bottom_part-active.png';
import atom_stand_part_active from '../../public/assets/images/atom body_parts/atom_stand_part-active.png';

import Gloss from '../../public/assets/images/colors/Gloss.png';
import Matte from '../../public/assets/images/colors/Matte.png';

import arrow_212121 from '../../public/assets/images/arrow_212121.png';
import arrow_424242 from '../../public/assets/images/arrow_424242.png';
import arrow_828282 from '../../public/assets/images/arrow_828282.png';

import home from '../../public/assets/images/home.png';
import light from '../../public/assets/images/light.png';
import reset from '../../public/assets/images/reset.png';
import shere from '../../public/assets/images/shere.png';
import download from '../../public/assets/images/download.png';

import home_active from '../../public/assets/images/home_active.png';
import light_active from '../../public/assets/images/light_active.png';
import reset_active from '../../public/assets/images/reset_active.png';
import shere_active from '../../public/assets/images/shere_active.png';

import menu from '../../public/assets/images/menu.png';
import menu_active from '../../public/assets/images/menu_active.png';


import green_close from '../../public/assets/images/green close.png';

import whatsapp from '../../public/assets/images/whatsapp.png';
import email from '../../public/assets/images/email.png';
import copy_icon from '../../public/assets/images/copy_icon.png';


import lightWhite from '../../public/assets/images/lights/White.png';
import lightCyan from '../../public/assets/images/lights/Cyan.png';
import lightDarkBlue from '../../public/assets/images/lights/Dark Blue.png';
import lightGreen from '../../public/assets/images/lights/Green.png';
import lightLightGreen from '../../public/assets/images/lights/Light Green.png';
import lightOrange from '../../public/assets/images/lights/Orange.png';
import lightPink from '../../public/assets/images/lights/Pink.png';
import lightPurple from '../../public/assets/images/lights/Purple.png';
import lightRed from '../../public/assets/images/lights/Red.png';
import lightYellow from '../../public/assets/images/lights/Yellow.png';

import minimize from '../../public/assets/images/minimize.png';
import minimize_2 from '../../public/assets/images/minimize_2.png';
import close_mobile from '../../public/assets/images/close_mobile.png';


import cart from '../../public/assets/images/cart.png';
import cart_active from '../../public/assets/images/cart_active.png';

import price_summer_close from '../../public/assets/images/price_summer_close.png';


import AR_outline from '../../public/assets/images/AR outlines.svg';

import atom_black from '../../public/assets/images/qr/atom_black.png';
import atom_white from '../../public/assets/images/qr/atom_white.png';
import origin_black from '../../public/assets/images/qr/origin_black.png';
import origin_white from '../../public/assets/images/qr/origin_white.png';


// import  from '../../public/assets/images';

let ar_icons = {
  atom_black : atom_black,
  atom_white : atom_white,
  origin_black : origin_black,
  origin_white : origin_white,
}

export {
  bck,
  Loading,
  LogoText,
  logo,
  origin,
  atom,

  cap_part,
  top_part,
  middle_part,
  bottom_part,
  stand_part,
  cap_part_active,
  top_part_active,
  middle_part_active,
  bottom_part_active,
  stand_part_active,


  logo_circle,
  arIcon,

  Black,
  White,
  amphibian_green,
  columbus_blue,
  izamal_yellow,
  lobelia_blue,
  mithril_grey,
  opulent_brown,
  persian_red,
  prometheus_orange,
  shojohi_red,
  spanish_white,

  atom_cap_part,
  atom_top_part,
  atom_middle_part,
  atom_bottom_part,
  atom_stand_part,

  atom_cap_part_active,
  atom_top_part_active,
  atom_middle_part_active,
  atom_bottom_part_active,
  atom_stand_part_active,

  Gloss,
  Matte,

  arrow_212121,
  arrow_424242,
  arrow_828282,

  home,
  light,
  reset,
  shere,
  download,
  green_close,
  menu,

  home_active,
  light_active,
  reset_active,
  shere_active,
  menu_active,

  whatsapp,
  email,
  copy_icon,

  lightWhite,
  lightCyan,
  lightDarkBlue,
  lightGreen,
  lightLightGreen,
  lightOrange,
  lightPink,
  lightPurple,
  lightRed,
  lightYellow,

  minimize,
  minimize_2,
  close_mobile,
  cart,
  cart_active,
  price_summer_close,

  AR_outline,

  ar_icons,

}
