import { amphibian_green, atom_bottom_part, atom_bottom_part_active, atom_cap_part, atom_cap_part_active, atom_middle_part, atom_middle_part_active, atom_stand_part, atom_stand_part_active, atom_top_part, atom_top_part_active, Black, bottom_part, bottom_part_active, cap_part, cap_part_active, columbus_blue, Gloss, izamal_yellow, lightCyan, lightDarkBlue, lightGreen, lightLightGreen, lightOrange, lightPink, lightPurple, lightRed, lightWhite, lightYellow, lobelia_blue, Matte, middle_part, middle_part_active, mithril_grey, opulent_brown, persian_red, prometheus_orange, shojohi_red, spanish_white, stand_part, stand_part_active, top_part, top_part_active, White } from "../Helpers/ImageImport";

export const commonOptions = [
  {
    name : 'basic',
    key : 'basic',
    colors : [
      {
        name : 'White',
        icon : White,
        color_hex: '#FFFFFF',
        price: 0
      },
      {
        name : 'Black',
        icon : Black,
        color_hex: '#010102',
        price: 0
      },
    ]
  },
  {
    name : 'premium',
    key : 'premium',
    colors : [
      {
        name : 'Amphibian green',
        icon : amphibian_green,
        color_hex : '#2F4C47',
        price : 500
      },
      {
        name : 'Persian red',
        icon : persian_red,
        color_hex : '#781214',
        price : 500
      },
      {
        name : 'Spanish white',
        icon : spanish_white,
        color_hex : '#E6DDC5',
        price : 500
      },
      {
        name : 'Mithril grey',
        icon : mithril_grey,
        color_hex : '#7E7E7E',
        price : 500
      },
      {
        name : 'Prometheus orange',
        icon : prometheus_orange,
        color_hex : '#F15C22',
        price : 500
      },
      {
        name : 'Lobelia blue',
        icon : lobelia_blue,
        color_hex : '#1D2E4A',
        price : 500
      },
      {
        name : 'Columbus blue',
        icon : columbus_blue,
        color_hex : '#5A8DCA',
        price : 500
      },
      {
        name : 'Izamal yellow',
        icon : izamal_yellow,
        color_hex : '#FFC60C',
        price : 500
      },
      {
        name : 'Opulent brown',
        icon : opulent_brown,
        color_hex : '#AF722A',
        price : 500
      },
      {
        name : 'Shojohi red',
        icon : shojohi_red,
        color_hex : '#EE3424',
        price : 500
      },
    ]

  },
  // {
  //   name : 'special',
  //   key : 'special',
  //   colors : [
  //     {
  //       name : 'Brushed Stainless Steel',
  //       icon : brushed_stainless_steel,
          // color_hex : #'',
  //       texture : [
  //         {
  //           name : 'Gloss'
  //         },
  //         {
  //           name : 'Matt'
  //         }
  //       ]
  //     },
  //     {
  //       name : 'Copper',
  //       icon : copper,
  // color_hex : #'',
  //       texture : [
  //         {
  //           name : 'Gloss'
  //         },
  //         {
  //           name : 'Matt'
  //         }
  //       ]
  //     },
  //     {
  //       name : 'Candy Red',
  //       icon_color : candy_red,
  //       texture : [
  //         {
  //           name : 'Gloss'
  //         },
  //         {
  //           name : 'Matt'
  //         }
  //       ]
  //     },
  //   ]

  // },
]
export const ColorTexture = [
  {
    name : 'Gloss',
    key: 'gloss',
    icon : Gloss,
  },
  {
    name : 'Matte',
    key: 'matte',
    icon : Matte,
  }
]
export const SidebarSettings = [
  [
    {
      key : 'cap',
      icon : cap_part,
      icon_active : cap_part_active,
      options : commonOptions,
      transition_name : 'top_zoom_in',
    },
    {
      key : 'body_top',
      icon : top_part,
      icon_active : top_part_active,
      options : commonOptions,
      transition_name : 'middle_top_zoom_in',
    },
    {
      key : 'middle',
      icon : middle_part,
      icon_active : middle_part_active,
      options : commonOptions,
      transition_name : 'middle_zoom_in',
    },
    {
      key : 'body_bottom',
      icon : bottom_part,
      icon_active : bottom_part_active,
      options : commonOptions,
      transition_name : 'middle_bottom_zoom_in',
    },
    {
      key : 'stand',
      icon : stand_part,
      icon_active : stand_part_active,
      options : commonOptions,
      transition_name : 'bottom_zoom_in',
    },
  ],
  [
    {
      key : 'cap',
      icon : atom_cap_part,
      icon_active : atom_cap_part_active,
      options : commonOptions,
      transition_name : 'top_zoom_in',
    },
    {
      key : 'body_top',
      icon : atom_top_part,
      icon_active : atom_top_part_active,
      options : commonOptions,
      transition_name : 'rings_zoom_in',
    },
    {
      key : 'middle',
      icon : atom_middle_part,
      icon_active : atom_middle_part_active,
      options : commonOptions,
      transition_name : 'middle_zoom_in',
    },
    {
      key : 'body_bottom',
      icon : atom_bottom_part,
      icon_active : atom_bottom_part_active,
      options : commonOptions,
      transition_name : 'bottom_zoom_in',
    },
    {
      key : 'stand',
      icon : atom_stand_part,
      icon_active : atom_stand_part_active,
      options : commonOptions,
      transition_name : 'stand_zoom_in',
    },

  ]
]
export const Lights = [
  {
    name: 'Red',
    light_name: 'Red',
    icon : lightRed,
  },
  {
    name: 'Orange',
    light_name: 'Orange',
    icon : lightOrange,
  },
  {
    name: 'Yellow',
    light_name: 'Yellow',
    icon : lightYellow,
  },
  {
    name: 'Light Green',
    light_name: 'Light green',
    icon : lightLightGreen,
  },
  {
    name: 'Green',
    light_name: 'Green',
    icon : lightGreen,
  },
  {
    name: 'Cyan',
    light_name: 'Cyan',
    icon : lightCyan,
  },
  {
    name: 'Dark Blue',
    light_name: 'Dark blue',
    icon : lightDarkBlue,
  },
  {
    name: 'Purple',
    light_name: 'Purple',
    icon : lightPurple,
  },
  {
    name: 'Pink',
    light_name: 'Pink',
    icon : lightPink,
  },
  {
    name: 'White',
    light_name: 'Light',
    icon : lightWhite,
  },
]

