/* eslint-disable no-return-assign */
import initialState from '../initialState';
import {
  SET_MODEL_CLICKED_PART,
} from '../../Constants/storeActionTypes';


export default function (state = initialState, action) {
  // let newState = {};
  switch (action.type) {
    case SET_MODEL_CLICKED_PART:
      return Object.assign({}, state, {
        part_clicked: action.payload
      })

    default:
      return state;
  }

}


