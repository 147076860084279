/* eslint-disable no-return-assign */
import initialState from '../initialState';
import {
  RESET_CONFIG_OPTIONS,
  SET_CONFIG_OPTIONS,
  SET_CONFIG_OPTIONS_FINALE,
  SET_CONFIG_PART_SELECTED,

} from '../../Constants/storeActionTypes';



export default function (state = initialState, action) {
  // let newState = {};
  switch (action.type) {
    case SET_CONFIG_OPTIONS:
      return Object.assign({}, state, {
        options: {
          ...state.options,
          [action.payload.key_part] :{
            ...state.options[action.payload.key_part],
            ...action.payload.values
          }
        }
      })

    case SET_CONFIG_OPTIONS_FINALE:
      return Object.assign({}, state, {
        options_finale: action.payload,
      })

    case SET_CONFIG_PART_SELECTED:
      return Object.assign({}, state, {
        config_part_selected: action.payload
      })
    case RESET_CONFIG_OPTIONS:
      return {
        config_part_selected:  null,
        options:{
          cap:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          body_top:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          middle:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          body_bottom:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          stand:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          light:{
            light_selected : null,
            light_name_old : 'Red',
            price: 0,
          },
        },
        options_finale:{
          cap:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          body_top:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          middle:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          body_bottom:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          stand:{
            color_type : 0,
            color_type_defult_selected : true,
            color_type_key : 'basic',
            color: 1,
            color_hex : '#010102',
            color_texture: 1,
            color_texture_key : 'matte',
            price : 0,
          },
          light:{
            light_selected : null,
            light_name_old : 'Red',
            price: 0,
          },
        }
      }

    default:
      return state;
  }

}


