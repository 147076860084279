import { combineReducers } from 'redux';
import ConfigReducer from './Reducers/ConfigReducer';
import ModalReducer from './Reducers/ModalReducer';
import ModelReducer from './Reducers/ModelReducer';
import SiteReducer from './Reducers/SiteReducer';


const appReducer = combineReducers({
  model: ModelReducer,
  config : ConfigReducer,
  modal : ModalReducer,
  site : SiteReducer,
});

const rootReducer = (state, action) => {
  // if(action.type === 'USER_LOGED_OUT'){
  //   state= undefined;
  // }

  return appReducer(state, action);
};

export default rootReducer;

