
import {
  SET_MODAL_STATE,

} from '../../Constants/storeActionTypes';


export const setModalState = payload => (dispatch) => {
  dispatch({
    type: SET_MODAL_STATE,
    payload,
  });
  return Promise.resolve();
};






