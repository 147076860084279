/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from '../Helpers/ImageImport';

const CustomContainer = styled('div')`
  /* position:relative ; */
  width: 100vw;
  height: 100vh;



  @media (max-width: 575px) {
    width: 100%;
    height: 100vh;

  }
  div{
    width: 100%;
    height: 100%;

  }
  #loading{
    /* display: none; */
    position: absolute;
    left: 0;
    top: 0 ;

    max-width:  100%;
    height: 100% ;
    width: 100%;
    /* height:  calc(100% - 150px); */
    z-index: 999;
    object-fit: contain;
    object-position: center;
    background: black;
  }
`
const Model = (props) => {
  let options = {};
  if(checkDevice() == 'desktop') options = props.options.desktop;
  else options = props.options.mobile;

  // console.log(options, checkDevice())
  Unlimited3D.init(options, {}, (error, status) =>{
    if (error || !status) {
        console.log(error, status);
        return;
    }

    document.querySelector('#loading').style.display = 'none';

  });
  console.log('test')
  return (
    <CustomContainer>
      <div id='container3d'></div>
      <img src={Loading} alt="" id="loading" />
    </CustomContainer>
  );
}

export default Model;

Model.propTypes = {
  options: PropTypes.object.isRequired,
};
