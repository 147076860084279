import React, { Component } from 'react'
import styled from 'styled-components';
import Api from '../Api/ApiHelper';
import { Loading } from '../Helpers/ImageImport';

const CustomContainer = styled('div')`
  width: 100vw;
  height: 100vh;
  div{
    width: 100%;
    height: 100%;
    display: none;
  }
  img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
export default class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count : 0,
      count_2 : 0,
      engravings_loaded : false,
      upload_designe_loaded : false,
    }
  }

  componentDidMount = () =>{
    let url = window.location.pathname;
    let id = url.substring(url.lastIndexOf('/') + 1);


    Api.getConfigurator(id).then((res) => {
        let tuf = JSON.parse(res.tuf);
        console.log(tuf)
        let configuration = { ...tuf.choose_your_colours }

        Unlimited3D.init(tuf.model_options, configuration, (error, status) => {
          // document.querySelector('#container3d').style.display = 'block';
          if (error || !status) {
            console.log(error, status);
            return;
          }

        });
    })
    .catch((err) => {
      console.log(err);
    });

  }
  render() {
    console.log(this.state)
    if(this.state.engravings_loaded && this.state.upload_designe_loaded){
      setTimeout(() => {
        document.querySelector('#loading').style.display = 'none';
        document.querySelector('#container3d').style.display = 'block';
      }, 1000);
    }
    return (
      <CustomContainer>
        <div id='container3d'></div>
        <img id="loading" src={Loading} alt="Loading" />
      </CustomContainer>
    )
  }
}
