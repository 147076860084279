/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components'
import { SidebarSettings } from '../Constants/SidebarSettings'
import SelectPart from './configModel/SelectPart'
import PropTypes from 'prop-types';
import Options from './configModel/Options';
import { arrow_212121, close_mobile, minimize, minimize_2 } from '../Helpers/ImageImport';
import { setSelectedRightsbar } from '../Store/Actions/SiteActions';
import { connect } from 'react-redux';
const Wrapper = styled.div`
  position:absolute ;
  left:0 ;
  top:0 ;
  height:100%;
  width:auto ;
  display:flex ;
  .arrow_tabs{
    position: absolute;
    right:0 ;
    top:50% ;
    transform: translate(90%, -50%);
    z-index: 5;
  }
  .title{
    display: none;
  }
  .icons_mobile{
    position: absolute;
    right: 1.3rem;
    /* top: auto; */
    display: flex;
    align-items: center;

    img{
      cursor: pointer;
    }
    img:first-of-type{
      cursor: pointer;
      margin-right: 9px;
    }
  }
  @media (max-width: 575px) {
    display: none;
    height: auto;
    top: initial;
    bottom: 58px;
    background-color: #424242;
    width: 100%;
  }
`
const PartWrapper = styled.div`
  position:relative;
  background: #212121;
  height:100%;
  width: 10rem;
  display: flex ;
  align-items:center ;
  justify-content: center ;
  flex-direction: column ;
`
const OptionsWraper = styled.div`
  display:flex ;

  @media (max-width: 575px) {
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    .title{
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;

      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #C8FF01;
      border-bottom: 2px solid #212121;
      padding: 1.3rem 0 1.3rem 2rem;
      margin: 0 1rem;
    }
  }

`
function Sidebar(props) {
  const [minimize_flag, setminimize_flag] = React.useState(true)
  const minimizeHandle = () => {
    setminimize_flag(!minimize_flag)
    if(minimize_flag){
      document.querySelector('#SelectPart').style.display = 'none';
      document.querySelector('#OptionsWrap').style.display = 'none';
      document.querySelector('#ColorWrapper').style.display = 'none';
      document.querySelector('#TextureWrapper').style.display = 'none';
    }else{
      document.querySelector('#SelectPart').style.display = 'flex';
      document.querySelector('#OptionsWrap').style.display = 'flex';
      document.querySelector('#ColorWrapper').style.display = 'flex';
      document.querySelector('#TextureWrapper').style.display = 'flex';
    }
  }
  const handelClose = () => {
    setminimize_flag(true)
    props.setSelectedRightsbar(null);
    Unlimited3D.activateTransition({transition:'zoom_out'  }, () =>{});
    document.querySelector('#config').style.display = 'none';
    document.querySelector('#SelectPart').style.display = 'flex';
    document.querySelector('#OptionsWrap').style.display = 'flex';
    document.querySelector('#ColorWrapper').style.display = 'flex';
    document.querySelector('#TextureWrapper').style.display = 'flex';
  }
  return (
    <Wrapper id="config">
      <PartWrapper
        id='SelectPart'
      >
        {
          SidebarSettings[props.selected_model].map((value, key) =>(
            <SelectPart
              key={key}
              data={value}
              setSelectedPart={props.setSelectedPart}
              selected={props.config_part_selected}
            />
          ))
        }
        { props.config_part_selected && <img className="arrow_tabs" src={arrow_212121} alt="arrow_212121" /> }
      </PartWrapper>
      <OptionsWraper>
        <div className="title">
          configuration Options
          <div className="icons_mobile">
            <img onClick={minimizeHandle } src={ minimize_flag ? minimize : minimize_2} alt="minimize" />
            <img onClick={handelClose} src={close_mobile} alt="close_mobile" />
          </div>
        </div>
        {
          props.config_part_selected &&
          <Options
            onSelectColorByPart={props.onSelectColorByPart}
            config_selected={props.config_selected}
            setMaterialColor={props.setMaterialColor}
            setMaterialTexture={props.setMaterialTexture}
            model_params_config_part_selected={props.model_params.config[props.config_part_selected]}
          />
        }
      </OptionsWraper>
    </Wrapper>
  )
}

Sidebar.propTypes = {
  config_part_selected : PropTypes.string,
  config_selected : PropTypes.object,
  setSelectedPart : PropTypes.func,
  selected_model: PropTypes.number,
  onSelectColorByPart : PropTypes.func.isRequired,
  setMaterialColor : PropTypes.func,
  setMaterialTexture : PropTypes.func,
  model_params : PropTypes.object.isRequired,
  setSelectedRightsbar : PropTypes.func,
}
const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps, { setSelectedRightsbar })(Sidebar);
