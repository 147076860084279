
import {
  SET_SELECTED_RIGHTBAR,

} from '../../Constants/storeActionTypes';


// export const updateNotificationAction = payload => (dispatch) => {
//   dispatch({
//     type: UPDATE_NOTIFICATION,
//     payload,
//   });
// };

export const setSelectedRightsbar = payload => (dispatch) => {
  dispatch({
    type: SET_SELECTED_RIGHTBAR,
    payload,
  });
  return Promise.resolve();
};






