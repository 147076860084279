import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CustomImg = styled.img`
  margin: 1.5rem 0 ;
  cursor: pointer;
  width:5rem;
  /* width:${ props => props.selected ? '4.9rem' : '4rem'}  ; */

  @media (max-width: 575px) {
  margin: 0.7rem 0 ;

  }
`
export default function SelectPart(props) {
  return (
    <CustomImg
      src={ props.data.key == props.selected ? props.data.icon_active : props.data.icon}
      alt="parts"
      selected={ props.data.key == props.selected }
      onClick={() => props.setSelectedPart(props.data.key, props.data.transition_name)}
    />
  )
}

SelectPart.propTypes = {
  data : PropTypes.object.isRequired,
  setSelectedPart : PropTypes.func.isRequired,
  selected : PropTypes.string,
}
