/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Lights } from '../../Constants/SidebarSettings';
import { connect } from 'react-redux';
import { setConfigOptions } from '../../Store/Actions/ConfigActions';
import { close_mobile, minimize, minimize_2 } from '../../Helpers/ImageImport';
import { setSelectedRightsbar } from '../../Store/Actions/SiteActions';

const MainWrapper = styled.div`
  position: absolute;
  right: 13rem;
  top: 1rem;
  overflow-y: auto;

  width : 12.5rem;
  background: #828282cc;
  border-radius: 15px;
  padding-top: 1.6rem;

  max-height: calc(100vh - 135px);

  .title{
    display: none;
  }
  .icons_mobile{
    position: absolute;
    right: 1.3rem;
    /* top: auto; */
    display: flex;
    align-items: center;

    img{
      cursor: pointer;
    }
    img:first-of-type{
      cursor: pointer;
      margin-right: 9px;
    }
  }
  @media (max-width: 575px) {
    padding-top: 0;
    border-radius: initial;
    background: #424242;
    top: initial;
    bottom: 58px;
    left: 0;
    right: initial;
    width: 100vw;
    height: auto;
    max-height: initial;
    overflow-x: auto;
    .title{
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;

      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #C8FF01;
      border-bottom: 2px solid #212121;
      padding: 1.3rem 0 1.3rem 1.3rem;
      margin: 0 1rem;
    }
  }

`
const LightsWrapper = styled.div`
  @media (max-width: 575px) {
    padding: 0 0.5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    align-items: baseline;
    /* flex-wrap: wrap; */
  }
`
const LightsOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;

  margin-bottom: 1.6rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  img{
    cursor: pointer;
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: 7px;
  }
  .selected{
    /* border: 1.5px solid #FFFFFF;
    padding: 1px; */
    border-radius: 50%;

    box-shadow: 0 0 9px -0.5px #c8ff01;
  }

  @media (max-width: 575px) {
    margin: 0 1.5rem ;
    margin-bottom: 0;
    min-width: 4.5rem;
    /* flex: 0 0 auto; */
  }
`
function LightsOptions(props) {
  const [minimize_flag, setminimize_flag] = React.useState(true)
  const minimizeHandle = () => {
    setminimize_flag(!minimize_flag)
    if(minimize_flag){
      document.querySelector('#LightsWrapper').style.display = 'none'
    }else{
      document.querySelector('#LightsWrapper').style.display = 'flex'
    }
  }
  const handelClose = () => {
    props.setSelectedRightsbar(null);
    props.setLightsFlag(false)
    document.querySelector('#LightsWrapper').style.display = 'flex'
  }
  const changeLight = (light_name, key) =>{
    Unlimited3D.hideLights({ lights:['Red', 'Orange', 'Yellow', 'Light green', 'Green', 'Cyan', 'Dark blue', 'Purple', 'Pink', 'Light'] }, (e1) =>{ console.log(e1)
      Unlimited3D.showLights({ lights:[light_name] }, (e) =>{ console.log(e)
        props.setConfigOptions({
          key_part : 'light',
          values : {
            light_selected : key,
            light_name_old : light_name,
          }
        });
      });
    });
  }
  return (
    <MainWrapper>
      <div className="title">
        LED Light switch
        <div className="icons_mobile">
          <img onClick={minimizeHandle } src={ minimize_flag ? minimize : minimize_2} alt="minimize" />
          <img onClick={handelClose} src={close_mobile} alt="close_mobile" />
        </div>
      </div>
      <LightsWrapper id='LightsWrapper'>
        {
          Lights.map((light, key) =>
            <LightsOption
              key={key}
              onClick={() => changeLight(light.light_name, key)}
            >
              <img className={`${props.lightParams.light_selected == key ? "selected" : ''}`} src={light.icon} alt={light.name} />
              {light.name}
            </LightsOption>

          )
        }
      </LightsWrapper>
    </MainWrapper>
  )
}

LightsOptions.propTypes = {
  setConfigOptions : PropTypes.func,
  lightParams : PropTypes.object.isRequired,
  setSelectedRightsbar : PropTypes.func,
  setLightsFlag : PropTypes.func,
}
const mapStateToProps = (state) => ({
  lightParams : state.config.options.light,
});
export default connect(mapStateToProps, { setConfigOptions, setSelectedRightsbar })(LightsOptions);
