import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setModalState } from '../Store/Actions/ModalActions';
import Api from '../Api/ApiHelper';
import { ColorTexture, commonOptions } from '../Constants/SidebarSettings';

const MainWrapper = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    display: none;
  }
  .price_wrapper{
    display: flex;
    justify-content: space-between;

    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    letter-spacing: 0.04em;
    margin-bottom: 2.5rem;
  }

  .checkout_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button_summery{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-right: 2rem;
  }
  .button_checkout{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    background: linear-gradient(90deg, #5DB95D 0%, #B2D220 100%);
    border-radius: 10px;
    padding: 5px 12px
  }
`
function CheckoutWrap(props) {

  const handleSummeryClick = () =>{
    props.setModalState({
      modal_key : 'price_summery',
      show : true,
    })
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    minimumFractionDigits: 0,
  });
  let full_price = props.model_price ;

  Object.values(props.config_options).map((option) => (
    full_price += option.price
  ));
  const checkoutClikc = () => {
    const pdf_params = {
      cap : {
        color_type : commonOptions[props.config.options_finale.cap.color_type].name,
        color : commonOptions[props.config.options_finale.cap.color_type].colors[props.config.options_finale.cap.color].name,
        color_texture : ColorTexture[props.config.options_finale.cap.color_texture].name,
        cost : formatter.format(props.config.options_finale.cap.price),
      },
      body_top : {
        color_type : commonOptions[props.config.options_finale.body_top.color_type].name,
        color : commonOptions[props.config.options_finale.body_top.color_type].colors[props.config.options_finale.body_top.color].name,
        color_texture : ColorTexture[props.config.options_finale.body_top.color_texture].name,
        cost : formatter.format(props.config.options_finale.body_top.price),
      },
      middle : {
        color_type : commonOptions[props.config.options_finale.middle.color_type].name,
        color : commonOptions[props.config.options_finale.middle.color_type].colors[props.config.options_finale.middle.color].name,
        color_texture : ColorTexture[props.config.options_finale.middle.color_texture].name,
        cost : formatter.format(props.config.options_finale.middle.price),
      },
      body_bottom : {
        color_type : commonOptions[props.config.options_finale.body_bottom.color_type].name,
        color : commonOptions[props.config.options_finale.body_bottom.color_type].colors[props.config.options_finale.body_bottom.color].name,
        color_texture : ColorTexture[props.config.options_finale.body_bottom.color_texture].name,
        cost : formatter.format(props.config.options_finale.body_bottom.price),
      },
      stand : {
        color_type : commonOptions[props.config.options_finale.stand.color_type].name,
        color : commonOptions[props.config.options_finale.stand.color_type].colors[props.config.options_finale.stand.color].name,
        color_texture : ColorTexture[props.config.options_finale.stand.color_texture].name,
        cost : formatter.format(props.config.options_finale.stand.price),
      },
      additional_cost : formatter.format(full_price),
    }
    const data_to_send = new FormData();
    data_to_send.append('speaker_key', props.model_name.toLowerCase());
    data_to_send.append('pdf_params', JSON.stringify(pdf_params))
    Api.saveConfigurator(data_to_send).then((res) => {
      parent.postMessage(`configuratorMessage:product=${props.model_name.toLowerCase()}&config_id=${res.config_id}`, "*");
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }
  // console.log(props.config.options_finale)
  return (
    <MainWrapper>
      <div className="price_wrapper">
        <div>Price:</div>
        <div className="price">{formatter.format(full_price)}</div>
      </div>
      <div className="checkout_wrapper">
        <div className="button_summery" onClick={() => handleSummeryClick() }>Price Summary</div>
        <div onClick={() => checkoutClikc()} className="button_checkout">Checkout</div>
      </div>
    </MainWrapper>
  )
}

CheckoutWrap.propTypes = {
  modal : PropTypes.object.isRequired,
  setModalState : PropTypes.func,
  config_options : PropTypes.object.isRequired,
  config : PropTypes.object.isRequired,
  model_name : PropTypes.string,
  model_price : PropTypes.number,
}
const mapStateToProps = (state) => ({
  modal : state.modal,
  config_options : state.config.options,
  config : state.config,
});
export default connect(mapStateToProps, { setModalState })(CheckoutWrap);
