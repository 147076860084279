export const SET_MODEL_CLICKED_PART = 'SET_MODEL_CLICKED_PART';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';


export const SET_CONFIG_OPTIONS = 'SET_CONFIG_OPTIONS';
export const SET_CONFIG_OPTIONS_FINALE = 'SET_CONFIG_OPTIONS_FINALE';
export const SET_CONFIG_PART_SELECTED = 'SET_CONFIG_PART_SELECTED';
export const RESET_CONFIG_OPTIONS = 'RESET_CONFIG_OPTIONS';

export const SET_MODAL_STATE = 'SET_MODAL_STATE';



export const SET_SELECTED_RIGHTBAR = 'SET_SELECTED_RIGHTBAR';
