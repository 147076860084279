/* eslint-disable no-undef */
export const Models = [
  {
    options:{
      desktop :{
        distID:  'latest',
        solution3DName: 'origin',
        projectName: 'veddan-config',
        solution3DID:  '40594',
        containerID: 'container3d',
      },
      mobile:{
        distID:  'latest',
        solution3DName: 'origin-mobile',
        projectName: 'veddan-config',
        solution3DID:  '41629',
        containerID: 'container3d',
      }
    },
    deafultResetOptions : (callback = () =>{} ) =>{
      Unlimited3D.changeMaterial( { parts : ['Fillet2_001'], material : 'top cap_matte' }, () =>{
        Unlimited3D.changeMaterial( { parts : ['Process-Bends1_004'], material : 'top part_matte' }, () =>{
          Unlimited3D.changeMaterial( { parts : ['Fillet1_006', 'Boss-Extrude2_003'], material : 'inner ring_matt' }, () =>{
            Unlimited3D.changeMaterial( { parts : ['Extrude-Thin1_001'], material : 'bottom part_matte' }, () =>{
              Unlimited3D.changeMaterial( { parts : ['Boss-Extrude1_009'], material : 'bottom cap_matte' }, () =>{
                Unlimited3D.changeMaterialColor( {material : 'top cap_matte', color: '#191919' }, () => {
                  Unlimited3D.changeMaterialColor( {material : 'top part_matte', color: '#191919' }, () => {
                    Unlimited3D.changeMaterialColor( {material : 'inner ring_matt', color: '#FFFFFF' }, () => {
                      Unlimited3D.changeMaterialColor( {material : 'bottom part_matte', color: '#191919' }, () => {
                        Unlimited3D.changeMaterialColor( {material : 'bottom cap_matte', color: '#191919' }, () => {
                        Unlimited3D.hideLights({ lights:['Red', 'Orange', 'Yellow', 'Light green', 'Green', 'Cyan', 'Dark blue', 'Purple', 'Pink', 'Light'] }, () =>{
                            callback();
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
    ,
    config :{
      cap : {
        materials:{
          gloss : 'top cap_gloss',
          matte : 'top cap_matte',
        },
        parts: ['Fillet2_001'],
      },
      body_top : {
        materials:{
          gloss : 'top part_gloss',
          matte : 'top part_matte',
        },
        parts: ['Process-Bends1_004'],
      },
      middle : {
        materials : {
          gloss : 'inner ring_gloss',
          matte : 'inner ring_matt',
        },
        parts: ['Fillet1_006', 'Boss-Extrude2_003'],
      },
      body_bottom : {
        materials:{
          gloss : 'bottom part_gloss',
          matte : 'bottom part_matte',
        },
        parts: ['Extrude-Thin1_001'],
      },
      stand : {
        materials:{
          gloss : 'bottom cap_gloss',
          matte : 'bottom cap_matte',
        },
        parts: ['Boss-Extrude1_009'],
      },
    },
    ar_names : {
      white: 'origin_white',
      black: 'origin_black',
    },
    name : 'origin',
    price : 9990,
  },
  {
    options:{
      desktop : {
        distID:  'latest',
        solution3DName: 'atom-2',
        projectName: 'veddan-config',
        solution3DID:  '41555',
        containerID: 'container3d',
      },
      mobile : {
        distID:  'latest',
        solution3DName: 'atom-mobile',
        projectName: 'veddan-config',
        solution3DID:  '41649',
        containerID: 'container3d',
      }
    },
    deafultResetOptions : (callback = () =>{} ) =>{
      Unlimited3D.changeMaterial( { parts : ['Chamfer1_004'], material : 'top cap_matte' }, () =>{
        Unlimited3D.changeMaterial( { parts : ['Chamfer3_002'], material : 'inner ring_matt' }, () =>{
          Unlimited3D.changeMaterial( { parts : ['Base-Flange2_002'], material : 'middle part_default' }, () =>{
            Unlimited3D.changeMaterial( { parts : ['Cut-Extrude19_001'], material : 'bottom part_matte' }, () =>{
              Unlimited3D.changeMaterial( { parts : ['CirPattern1_003'], material : 'bottom cap_matte' }, () =>{
                Unlimited3D.changeMaterialColor( {material : 'top cap_matte', color: '#191919' }, () => {
                  Unlimited3D.changeMaterialColor( {material : 'inner ring_matt', color: '#191919' }, () => {
                    Unlimited3D.changeMaterialColor( {material : 'middle part_default', color: '#191919' }, () => {
                      Unlimited3D.changeMaterialColor( {material : 'bottom part_matte', color: '#191919' }, () => {
                        Unlimited3D.changeMaterialColor( {material : 'bottom cap_matte', color: '#191919' }, () => {
                          callback();
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    },
    config :{
      cap : {
        materials:{
          gloss : 'top cap_gloss',
          matte : 'top cap_matte',
        },
        parts: ['Chamfer1_004'],
      },
      body_top : {
        materials : {
          gloss : 'inner ring_gloss',
          matte : 'inner ring_matt',
        },
        parts: ['Chamfer3_002'],
      },
      middle : {
        materials : {
          gloss : 'middle part_gloss',
          matte : 'middle part_default',
        },
        parts: ['Base-Flange2_002'],
      },
      body_bottom : {
        materials:{
          gloss : 'bottom part_gloss',
          matte : 'bottom part_matte',
        },
        parts: ['Cut-Extrude19_001'],
      },
      stand : {
        materials:{
          gloss : 'speaker stand_gloss',
          matte : 'speaker stand_matte',
        },
        parts: ['CirPattern1_003'],
      },
    },
    ar_names : {
      white: 'atom_white',
      black: 'atom_black',
    },
    name : 'atom',
    price : 4998,
  },
];

