/* eslint-disable no-undef */
import axios from 'axios';
// import Cookies from 'universal-cookie';



// const cookies = new Cookies();

export const ApiAxios = axios.create({
    baseURL: `${process.env.API_URL}`,
    timeout: 90000, // javi gresku nakon 30s ako nema odgovora
    // da moze da se salje slika ?

    validateStatus(status) {
        return (status >= 200 && status <= 299); // Reject ako status nije 2xx
    },
});


const Api = {

    // setToken() {
    //     const state = store.getState();
    //     if (`Bearer ${state.user.token}` === ApiAxios.defaults.headers.common.Authorization) return;

    //     if (state.user.token === null) {
    //         const token = cookies.get('token');
    //         // ja bih ovde stavio ako nije undifaineda da uradi update token
    //         updateToken(token, store.dispatch);
    //         return;
    //     }

    //     const expiry = new Date();
    //     expiry.setDate(expiry.getDate() + 360);
    //     cookies.set('token', state.user.token, {
    //         path: '/',
    //         expires: expiry,
    //     });
    //     // updejtujemo da ima token za svaki poziv api-a

    //     ApiAxios.defaults.headers.common.Authorization = `Bearer ${state.user.token}`;

    // },

    handleResponse(response, transformation) {
      if (response.data.errors.length > 0) {
        if(response.data.exception === 'Exception'){
          // alert(response.data.errors[0].message);
          throw []
        }
        throw response.data.errors;
      }

      if (transformation) {
        return transformation(response.data.res);
      }
      return response.data.res;
    },

    post(route, data, transformation = null) {
      ApiAxios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      return Promise.resolve(true)
        .then(() => {
          return ApiAxios.post(`${process.env.API_URL}`+ route, data);
        })
        .then(response => Api.handleResponse(response, transformation));
    },
    postFormData(route, data, transformation = null) {
      ApiAxios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      return Promise.resolve(true)
        .then(() => {
          return ApiAxios.post(`${process.env.API_URL}`+ route, data, );
        })
        .then(response => Api.handleResponse(response, transformation));
    },
    get(route, transformation = null) {
      return Promise.resolve(true)
        .then(() => {
          return ApiAxios.get(`${process.env.API_URL}`+ route);
        })
        .then(response => Api.handleResponse(response, transformation));
    },

    saveConfigurator(data){
      return Api.postFormData('api/configuration/save',data);
    },
    getConfigurator(id){
      return Api.get('api/configuration/'+id);
    }
};



export default Api;
