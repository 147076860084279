/* eslint-disable no-undef */
import React from "react";
import styled from 'styled-components';
import CheckoutWrap from "./Components/CheckoutWrap";
import ChooseSpeeker from "./Components/ChooseSpeeker";
import ConfigModel from "./Components/ConfigModel";
import TopBar from "./Components/configModel/TopBar";
import Configuration from "./Components/Configuration";
import Modal from "./Components/Modal";
import Model from "./Components/Model";
import RightSidebar from "./Components/RightSidebar";
import { Models } from "./Constants/ModelsParametars";
import { bck } from "./Helpers/ImageImport";


const AppWrapper = styled.div`

  /* background-image: url('${bck}') ; */
  background-position: center center ;
  background-repeat: no-repeat ;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
`;
const CustomLoading = styled.video`
  background-color: #000000;
  width: 100%;
  height: 100%;
`
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      selected_model : null,
      loaded : false
    }
  }

  setSelectedModel = (value) => {
    this.setState({
      selected_model : value,
    })
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({loaded : true})
    }, 9000);
  }
  render() {
    // let url_name_flag = window.location.pathname.includes('configuration');
    // if( !url_name_flag ){
      // console.log(loading_site_mobile)
      return (
        <AppWrapper className="App">
          <Modal
            setSelectedModel={this.setSelectedModel}
            model_params={Models[this.state.selected_model]}
            selected_model={this.state.selected_model}
          />
          {
            this.state.selected_model != null ?
            <React.Fragment>
              <TopBar
                ar_names={Models[this.state.selected_model].ar_names}
              />
              <ConfigModel
                model_params={Models[this.state.selected_model]}
                selected_model={this.state.selected_model}
              />
              <Model options={Models[this.state.selected_model].options} />
              <RightSidebar
                setSelectedModel={this.setSelectedModel}
                selected_model={this.state.selected_model}
              />
              <CheckoutWrap
                model_name={Models[this.state.selected_model].name}
                model_price={Models[this.state.selected_model].price}
              />
            </React.Fragment>
            :
            !this.state.loaded ?
              <CustomLoading autoPlay muted playsInline >
                <source src={checkDevice() == 'desktop' ? 'https://demo.threedium.co.uk/veddan/assets/videos/desktop_video.mp4' : 'https://demo.threedium.co.uk/veddan/assets/videos/mobile_video.mp4'} type="video/mp4"/>
                Your browser does not support the video tag.
              </CustomLoading>
            :
            <ChooseSpeeker setSelectedModel={this.setSelectedModel} />
          }
        </AppWrapper>
      );
      // } else {
      // {/* <CustomLoading src={checkDevice() == 'desktop' ? loading_site_desktop : loading_site_mobile} /> */}
    //   return(
    //     <AppWrapper className="App">
    //       <Configuration />
    //     </AppWrapper>
    //   )
    // }
  }
}

export default App;
