/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { green_close, price_summer_close } from '../Helpers/ImageImport';
import { connect } from 'react-redux';
import { setModalState } from '../Store/Actions/ModalActions';
import { resetConfigOptions } from '../Store/Actions/ConfigActions';
import { setSelectedRightsbar } from '../Store/Actions/SiteActions';
import { ColorTexture, commonOptions, SidebarSettings } from '../Constants/SidebarSettings';
import Api from '../Api/ApiHelper';

const MainWrapp = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 45px -7px rgba(200, 255, 1, 0.6);
  border-radius: 16px;
  max-width: 100%;
  width: ${ props => props.model_key == 'normal' ?  '50rem' : 'auto'};
  padding: 6rem 5rem;

  .price_bottom{
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    right: 0;
    margin-left: auto;
  }
  .summery_title{
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;

    letter-spacing: 0.18em;
    text-transform: uppercase;
  }
  .price_bottom span ,
  .button_checkout{
    display: none;
  }
  @media (max-width: 575px) {
    width: 97%;
    ${ props => props.model_key == 'price_summery' ?  `
      left: 0;
      top: 0;
      transform: initial;
      width: 100%;
      height: 100vh;
      background: #424242;
      padding: 8rem 2rem;
      z-index: 11;
      border-radius: 0;
    ` : ''};

    .price_bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 1.8rem;
      position: absolute;
      bottom: 8rem;
      width: 100%;
      padding: 0 2rem;
    }
    .price_bottom div{
      display: flex;
    }
    .price_bottom span {
      display: block;
      margin-right: 2rem;
    }
    .button_checkout{
      display: block;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;
      background: linear-gradient(90deg, #5DB95D 0%, #B2D220 100%);
      border-radius: 10px;
      padding: 5px 12px
    }
  }
`
const CloseIMG = styled.img`
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;

  @media (max-width: 575px) {
    ${ props => props.model_key == 'price_summery' ?  `
      right: initial;
      top: 3rem;
      left: 3rem;
    ` : ''};
  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 0.18em;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-bottom: 1.2rem;
`
const Desc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  text-align: center;
  letter-spacing: 0.18em;

  color: #FFFFFF;
  margin-bottom: 1.8rem;
`
const ButtonWrapp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  div{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    padding: 0.7rem 3.5rem;
    border-radius: 13px;
  }
  div:nth-child(1){
    position: relative;

    /* border: 3px solid; */

    box-sizing: border-box;


    color: #FFF;
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid 3px transparent; /* !importanté */
    border-radius: 1em;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(90deg, #5BB95E 0%, #ABD026 100%);
    }
  }

  div:nth-child(2){
    background: linear-gradient(90deg, #15AE5B 0%, #C8FF01 100%);
  }
`
const TableWrap = styled.table`

  border-spacing:0 1.7rem;
  .part_icons{
    width: 4rem;
    min-width: 4rem;
  }
  hr{
    margin: 0;
    border-bottom: 1px solid rgba(200, 255, 1, 0.5);
    width: auto;
    position: absolute;
    left: 5rem;
    right: 5rem;
  }

  th{
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    padding-right: 5rem;
  }
  th:last-of-type{
    padding-right: 0;
  }
  .price{
    white-space: pre;
  }
  .part_color{
    width: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    min-height: 4.5rem;
  }


  .border_circle {
    border: 1px solid #FFFFFF; border-radius: 50%;
  }

  @media (max-width: 575px) {
    .part_icons{
      width: 3rem;
      min-width: 3rem;
    }
    .part_color{
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
    }

    th{
      padding-right: 1rem;
    }
    th{
      font-size: 1.2rem;
    }
    .price{
      white-space: initial;
    }

    hr{

      left: 2rem;
      right: 2rem;
    }

  }
`
const Modal = (props) => {
  let full_price = props.model_params ? props.model_params.price : 0;
  const close = () => {
    props.setSelectedRightsbar(null)
    props.setModalState({
      modal_key : null,
      show : false,
      title :"",
      desc :"",
      left_button_text :"",
      right_button_text :"",
    });
  }
  const rightButton = () => {
    switch (props.modal.right_click_key) {
      case 'home':
        close();
        props.resetConfigOptions();
        props.setSelectedModel();
        break;
      case 'restart':
        close();
        document.querySelector('#loading').style.display = 'block';
        props.model_params.deafultResetOptions(() =>{
          document.querySelector('#loading').style.display = 'none';
          props.resetConfigOptions();
        })
        break;
      default:
        break;
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    minimumFractionDigits: 0,
  });
  const checkoutClikc = () => {
    const pdf_params = {
      cap : {
        color_type : commonOptions[props.config.options_finale.cap.color_type].name,
        color : commonOptions[props.config.options_finale.cap.color_type].colors[props.config.options_finale.cap.color].name,
        color_texture : ColorTexture[props.config.options_finale.cap.color_texture].name,
        cost : formatter.format(props.config.options_finale.cap.price),
      },
      body_top : {
        color_type : commonOptions[props.config.options_finale.body_top.color_type].name,
        color : commonOptions[props.config.options_finale.body_top.color_type].colors[props.config.options_finale.body_top.color].name,
        color_texture : ColorTexture[props.config.options_finale.body_top.color_texture].name,
        cost : formatter.format(props.config.options_finale.body_top.price),
      },
      middle : {
        color_type : commonOptions[props.config.options_finale.middle.color_type].name,
        color : commonOptions[props.config.options_finale.middle.color_type].colors[props.config.options_finale.middle.color].name,
        color_texture : ColorTexture[props.config.options_finale.middle.color_texture].name,
        cost : formatter.format(props.config.options_finale.middle.price),
      },
      body_bottom : {
        color_type : commonOptions[props.config.options_finale.body_bottom.color_type].name,
        color : commonOptions[props.config.options_finale.body_bottom.color_type].colors[props.config.options_finale.body_bottom.color].name,
        color_texture : ColorTexture[props.config.options_finale.body_bottom.color_texture].name,
        cost : formatter.format(props.config.options_finale.body_bottom.price),
      },
      stand : {
        color_type : commonOptions[props.config.options_finale.stand.color_type].name,
        color : commonOptions[props.config.options_finale.stand.color_type].colors[props.config.options_finale.stand.color].name,
        color_texture : ColorTexture[props.config.options_finale.stand.color_texture].name,
        cost : formatter.format(props.config.options_finale.stand.price),
      },
      additional_cost : formatter.format(full_price),
    }
    const data_to_send = new FormData();
    data_to_send.append('speaker_key', props.model_params.name.toLowerCase());
    data_to_send.append('pdf_params', JSON.stringify(pdf_params))
    Api.saveConfigurator(data_to_send).then((res) => {
      parent.postMessage(`configuratorMessage:product=${props.model_params.name.toLowerCase()}&config_id=${res.config_id}`, "*");
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }
  const ChosenModal = () => {
    switch (props.modal.modal_key) {
      case 'normal':
        return (
          <MainWrapp>
            <CloseIMG onClick={() => close()} src={green_close} alt="Close" />
            <Title>{ props.modal.title }</Title>
            <Desc>{ props.modal.desc }</Desc>
            <ButtonWrapp>
              <div onClick={() => close()} >{ props.modal.left_button_text }</div>
              <div onClick={() => rightButton() } >{ props.modal.right_button_text }</div>
            </ButtonWrapp>
          </MainWrapp>
        );
      case 'price_summery':
        return (
          <MainWrapp model_key={props.modal.modal_key}>
            <div className="summery_title">Price Summary</div>
            <CloseIMG onClick={() => close()} src={price_summer_close} alt="Close" model_key={props.modal.modal_key} />
            {/* <CloseIMG onClick={() => close()} src={price_summer_close} alt="Close" model_key={props.modal.modal_key} /> */}
            <TableWrap>
              <tbody>
              {
                Object.values(props.config.options_finale).map((option, key) => {
                  if(option.light_name_old) return;
                  full_price += option.price;
                  return(
                    <React.Fragment  key={key}>
                      <tr>
                        <th><img className='part_icons' src={SidebarSettings[props.selected_model][key].icon} alt={commonOptions[option.color_type].name} /></th>
                        <th>{ commonOptions[option.color_type].name } <br/> Colour</th>
                        <th><img className='part_color border_circle' src={ commonOptions[option.color_type].colors[option.color].icon } alt={commonOptions[option.color_type].colors[option.color].name} /></th>
                        <th>{ commonOptions[option.color_type].colors[option.color].name }</th>
                        <th><img className='part_color' src={ ColorTexture[option.color_texture].icon } alt={ColorTexture[option.color_texture].name} /></th>
                        <th>{ ColorTexture[option.color_texture].name }</th>
                        <th className='price'>{`Additional Cost:  ${formatter.format(option.price)}`}</th>
                      </tr>
                      <hr />
                    </React.Fragment>
                  )
                })
              }
              </tbody>
            </TableWrap>
            <div className='price_bottom'>
              <div>
                <span>Price:</span> {formatter.format(full_price)}
              </div>
              <div onClick={() => checkoutClikc()} className="button_checkout">Checkout</div>
              </div>
          </MainWrapp>
        );

      default:
        return (null);
    }
  }
  return (
    props.modal.show ?
      ChosenModal()
    :
      null
  );
}
Modal.propTypes = {
  modal : PropTypes.object.isRequired,
  setModalState : PropTypes.func,
  setSelectedModel : PropTypes.func,
  resetConfigOptions : PropTypes.func,
  model_params : PropTypes.object,
  setSelectedRightsbar : PropTypes.func,
  selected_model : PropTypes.number,

}

const mapStateToProps = (state) => ({
  modal : state.modal,
  config : state.config,
});
export default connect(mapStateToProps, { setModalState, resetConfigOptions, setSelectedRightsbar })(Modal);

