
export default {
  site: {
    selected_rightbar : '',
  },
  model:{
    part_clicked: null,
  },
  config:{
    config_part_selected:  null,
    // config_part_selected:  'cap',
    options:{
      cap:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      body_top:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      middle:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      body_bottom:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      stand:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      light:{
        light_selected : null,
        light_name_old : 'Red',
        price: 0,
      },
    },
    options_finale: {
      cap:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      body_top:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      middle:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      body_bottom:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      stand:{
        color_type : 0,
        color_type_defult_selected : true,
        color_type_key : 'basic',
        color: 1,
        color_hex : '#010102',
        color_texture: 1,
        color_texture_key : 'matte',
        price : 0,
      },
      light:{
        light_selected : null,
        light_name_old : 'Red',
        price: 0,
      },
    },
  },
  modal: {
    modal_key : 'price_summery',
    show: false,
    title: '',
    desc: '',
    left_button_text : '',
    right_button_text : '',
    right_click_key : null,
  }
};

