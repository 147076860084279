import React from 'react'
import styled from 'styled-components'
import { arrow_828282, cart, cart_active, download, home, home_active, light, light_active, menu, menu_active, reset, reset_active, shere, shere_active } from '../Helpers/ImageImport'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setModalState } from '../Store/Actions/ModalActions';
import ShereBox from './rightSidebar/ShereBox';
import { setSelectedRightsbar } from '../Store/Actions/SiteActions';
import LightsOptions from './rightSidebar/LightsOptions';

const MainWrapper = styled.div`
  position: absolute;
  right : 5rem;
  top : 50%;
  transform : translate(0 , -50%);
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content:center ;



  img{
    cursor: pointer;
    margin: 0.5rem 0;
    width: 4.5rem;
    height: 4.5rem;
    /* height: 3.5rem; */
  }
  .selected{


  }
  .light_icon_wrapper{
    position: relative;
  }
  .arrow_828282{
    width: 2.1rem !important;
    display: none;
    position: absolute;
    left: -1.5rem;
    top: 50%;
    transform: translate(-100%, -50%);
    width: auto;
    height: auto;
    margin: 0;
  }
  .show_arrow{
    display: block;
  }
  .mobile_icons{
    display: none;
  }
  @media (max-width: 575px) {
    bottom: 0;
    top: initial;
    left: 0;
    transform: none;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    background: #212121;
    z-index: 12;
    .arrow_828282{
      display: none;
    }
    .mobile_icons{
      display: block;
    }
  }

`

function RightSidebar(props) {
  const [shere_flag, setShereFlag] = React.useState(false);
  const [lights_flag, setLightsFlag] = React.useState(false);


  const closeModal = () =>{
    props.setModalState({
      modal_key : null,
      show: false,
      title: '',
      desc: '',
      left_button_text : '',
      right_button_text : '',
      right_click_key : '',
    })
  }
  return (
    <React.Fragment>
      <MainWrapper>
        <img
          onClick={ () => {
            props.setSelectedRightsbar(props.selected_rightbar == 'menu' ? null : 'menu');
            setShereFlag(false);
            setLightsFlag(false);
            closeModal();
            document.querySelector('#config').style.display = props.selected_rightbar == 'menu' ? 'none' : 'flex';
          }}
          className='mobile_icons'
          src={ props.selected_rightbar == 'menu' ? menu_active :menu} alt='menu'
        />
        <img
          onClick={() => {
              props.setSelectedRightsbar('home');
              props.setModalState({
                modal_key : 'normal',
                show: true,
                title: 'are you sure you want to return to the home page?',
                desc: 'Returning to the home page resets the configurator.',
                left_button_text : 'Cancel',
                right_button_text : 'Reset',
                right_click_key : 'home',
              })
              setShereFlag(false);
              setLightsFlag(false);
            }
          }
          className={`${props.selected_rightbar == 'home' ? 'selected' : ''}`}
          src={props.selected_rightbar == 'home' ? home_active : home} alt="home"
        />

        {
          props.selected_model == 1 ? null :
          <div className='light_icon_wrapper'>
            <img
              onClick={
                () => {
                  setLightsFlag(!lights_flag);
                  props.setSelectedRightsbar(props.selected_rightbar == 'light' ? null :'light');
                  setShereFlag(false);
                  closeModal();
                }
              }
              className={`${props.selected_rightbar == 'light' ? 'selected' : ''}`}
              src={props.selected_rightbar == 'light' ? light_active : light} alt="light" />
              <img className={`arrow_828282 ${props.selected_rightbar == 'light' ? 'show_arrow' : ''}`} src={arrow_828282} alt="arrow_828282" />
          </div>
        }

        <img
          onClick={() => {
              props.setSelectedRightsbar('reset');
              props.setModalState({
                modal_key : 'normal',
                show: true,
                title: 'Are you sure you want to reset your configuration?',
                desc: '',
                left_button_text : 'Cancel',
                right_button_text : 'Reset',
                right_click_key : 'restart',
              })
              setShereFlag(false);
              setLightsFlag(false);
            }
          }
          className={`${props.selected_rightbar == 'reset' ? 'selected' : ''}`}
          src={props.selected_rightbar == 'reset' ? reset_active : reset} alt="reset"
        />
        {/* <img
          onClick={
            () => {
              props.setSelectedRightsbar(props.selected_rightbar == 'shere' ? null : 'shere');
              setShereFlag(!shere_flag)
              setLightsFlag(false);
              closeModal();
            }
          }
          className={`${props.selected_rightbar == 'shere' ? 'selected' : ''}`}
          src={props.selected_rightbar == 'shere'? shere_active : shere} alt="shere" /> */}
        <img onClick={() => {}} src={ download} alt="download" />

        <img
          onClick={ () => {
            props.setSelectedRightsbar(props.selected_rightbar == 'cart' ? null : 'cart');
            setShereFlag(false);
            setLightsFlag(false);
            props.setModalState({
              modal_key : 'price_summery',
              show :  props.selected_rightbar == 'cart' ? false : true,
            })
            // document.querySelector('#config').style.display = props.selected_rightbar == 'cart' ? 'none' : 'flex';
          }}
          className='mobile_icons'
          src={ props.selected_rightbar == 'cart' ? cart_active : cart} alt='cart'
        />
      </MainWrapper>

      {
        shere_flag &&
        <ShereBox
          setShereFlag={
            () => {
              props.setSelectedRightsbar(null);
              setShereFlag(false);
            }
          }
        />
      }
      {
        lights_flag &&
        <LightsOptions
          setLightsFlag={setLightsFlag}
        />

      }
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  setSelectedModel : PropTypes.func,
  setModalState : PropTypes.func,
  setSelectedRightsbar : PropTypes.func,
  selected_model : PropTypes.number,
  selected_rightbar : PropTypes.string,

}

const mapStateToProps = (state) => ({
  selected_rightbar : state.site.selected_rightbar
});
export default connect(mapStateToProps, { setSelectedRightsbar, setModalState })(RightSidebar);
