/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components';
import { arIcon, AR_outline, ar_icons, green_close, logo_circle } from '../../Helpers/ImageImport'
import PropTypes from 'prop-types';
const ARWrapper = styled.div`
  position: relative;
  position: absolute;
  top: 3rem;
  right: 2rem;
  .AR-icon{
    cursor: pointer;
  }
  .ar_box{
    z-index: 15;
    display: none;
    position: absolute;
    left: -3rem;
    top: 0;
    padding: 3rem 2.6rem;
    transform: translate(-100% ,0%);
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 45px -7px rgba(200, 255, 1, 0.6);
    border-radius: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;

    text-align: center;
    letter-spacing: 0.04em;
    width: 24rem;

    flex-direction: column;
    align-items: center;
    img{
      margin-bottom: 1.6rem;
    }

    .close_icon{
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      cursor: pointer;
    }
  }
  .show{
    display: flex;
  }
  .custom_button{
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    background: #000000;
    position: relative;
    background-clip: padding-box; /* !importanté */
    border: solid 2px transparent; /* !importanté */
    border-radius: 10px;

    margin-top: 1.6rem;
    padding: 4px 12px;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -2px; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(90deg, #5BB95E 0%, #ABD026 100%);
    }


  }

`
const QRWrapper = styled('div')`
  display: flex;
  position: absolute;
  left: -3rem;
  top: 0;
  padding: 3rem 2.6rem;
  transform: translate(-100% ,0%);
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 45px -7px rgba(200, 255, 1, 0.6);
  border-radius: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;

  text-align: center;
  letter-spacing: 0.04em;
  width: 24rem;

  flex-direction: column;
  align-items: center;
  .qr_icon{
    width: 80%;
    margin-bottom: 1.5rem;
  }
  .qr_close_icon{
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    cursor: pointer;
  }
`
export default function TopBar(props) {
  const [show_qr, set_show_qr] = React.useState(false);
  const [show_ar_box, set_show_ar_box] = React.useState(false);
  const [src_qr, set_src_qr] = React.useState('');

  const closeArBox = () => {
    set_show_ar_box(false)
  }
  const HandelChangeModel = (name) =>{

    if (checkAr() == 'android') {
      document.getElementById("ar-button").setAttribute("rel", "ar");
      document.getElementById("ar-button").setAttribute(
          "href",
          "intent://arvr.google.com/scene-viewer/1.0?file=https://mango-glacier-0b4ea9003.1.azurestaticapps.net/models/"+name+".glb#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=%23;end;",
      );
      document.getElementById("ar-button").click();
      // arButton.addEventListener("click", arMobile )

    } else if (checkAr() == "ios" || checkAr() == 'ipad') {
        document.getElementById("ar-button").setAttribute("rel", "ar");
        document.getElementById("ar-button").setAttribute(
            "href",
            "https://mango-glacier-0b4ea9003.1.azurestaticapps.net/models/"+name+".usdz"
        );
        document.getElementById("ar-button").click();

        // arButton.addEventListener("click", arMobile )
    } else{
      set_src_qr(ar_icons[name])
      set_show_qr(true);
    }
  }
  return (
    <React.Fragment>
      <img className='logo_circle' src={logo_circle} alt="arIcon" />
      <ARWrapper>
        <div onClick={() => set_show_ar_box(!show_ar_box)}   className="AR-icon"><img  src={arIcon} alt="arIcon" /></div>
        {
          !show_qr ?
          <React.Fragment>
            <div className={`ar_box ${show_ar_box ? 'show' : ''}`}>
              <img onClick={ () => closeArBox() } className='close_icon' src={green_close} alt="green_close" />
              <img src={AR_outline} alt="AR_outline" />
              Choose a colour option to view in Augmented Reality.
              <div onClick={() => HandelChangeModel(props.ar_names.black)} className="custom_button">View in Black</div>
              <div onClick={() => HandelChangeModel(props.ar_names.white)} className="custom_button">View in White</div>
            </div>
          </React.Fragment> :
          <React.Fragment>
            <QRWrapper >
              <img onClick={() =>{ set_show_qr(false); show_ar_box(true) }} className='qr_close_icon' src={green_close} alt="green_close" />
              <img className='qr_icon' src={src_qr} alt="qr_icon" />
              <div>Scan this QR code on your phone to vide the speaker in your environment.</div>
            </QRWrapper>
          </React.Fragment>
        }
        <a href="#" id='ar-button'><img src="" alt="AR-icon" /></a>
      </ARWrapper>
    </React.Fragment>
  )
}

TopBar.propTypes = {
  ar_names : PropTypes.object.isRequired,
}
