/* eslint-disable no-undef */

import {
  RESET_CONFIG_OPTIONS,
  SET_CONFIG_OPTIONS,
  SET_CONFIG_OPTIONS_FINALE,
  SET_CONFIG_PART_SELECTED,

} from '../../Constants/storeActionTypes';



export const setConfigOptions = payload => (dispatch) => {
  dispatch({
    type: SET_CONFIG_OPTIONS,
    payload,
  });
  return Promise.resolve();
};
export const setConfigOptionsFinale = payload => (dispatch) => {
  dispatch({
    type: SET_CONFIG_OPTIONS_FINALE,
    payload,
  });
  return Promise.resolve();
};
export const setConfigPartSelected = payload => (dispatch) => {
  dispatch({
    type: SET_CONFIG_PART_SELECTED,
    payload,
  });
  return Promise.resolve();
};
export const resetConfigOptions = payload => (dispatch) => {
  dispatch({
    type: RESET_CONFIG_OPTIONS,
    payload,
  });
  return Promise.resolve();
};






