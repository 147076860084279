/* eslint-disable no-return-assign */
import initialState from '../initialState';
import {
  SET_MODAL_STATE,
} from '../../Constants/storeActionTypes';


export default function (state = initialState, action) {
  // let newState = {};
  switch (action.type) {
    case SET_MODAL_STATE:
      state = {
        modal_key : action.payload.modal_key,
        show: action.payload.show,
        title: action.payload.title,
        desc: action.payload.desc,
        left_button_text : action.payload.left_button_text,
        right_button_text : action.payload.right_button_text,
        right_click_key : action.payload.right_click_key,
      }
      return state

    default:
      return state;
  }

}


