import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { ColorTexture, commonOptions } from '../../Constants/SidebarSettings';
import { arrow_424242, arrow_828282 } from '../../Helpers/ImageImport';


const OptionsWrap = styled.div`
  position: relative;
  background: #424242;
  height:100%;
  width: 18rem;
  display: flex ;
  align-items:center ;
  justify-content: center ;
  flex-direction: column ;
  animation: fadeInFromNone 0.5s ease-out;
  div{
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    cursor: pointer;
    padding: 5rem 1rem 5rem 1rem;
  }
  > div + div {
    border-top: 1px solid #FFFFFF;
  }
  .selected{
    text-shadow: 0px 0px 10px #C8FF01;
  }

  @media (max-width: 575px) {
    padding: 1.3rem 2rem 1.3rem 2rem;
    flex-direction: row;
    justify-content: start;
    width: auto;
    height: auto;
    border-bottom: 2px solid #212121;
    margin: 0 1rem;
    div{
      border: none !important;
      font-size: 1.5rem;
      padding: 0 2rem 0 0;
    }
  }
`
const ColorWrapper = styled.div`
  max-height:100vh ;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background: #828282;
  height: 100%;
  width: 12rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  animation: fadeInFromNone 0.5s ease-out;
  /* justify-content: center; */
  gap: 2rem;

  > .color_ + .color_ {
    margin-top: 2rem;
  }
  ::before, ::after {
    content: '';  /* Insert pseudo-element */
    margin: auto; /* Make it push flex items to the center */
  }

  @media (max-width: 575px) {
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    flex-direction: row;
    width: auto;
    background: transparent;
    padding: 1.3rem 2rem 1.3rem 2rem;
    flex-wrap: wrap;
    border-bottom: 2px solid #212121;
    gap: 1rem;
    ::before, ::after {
      content: none;  /* Insert pseudo-element */
      margin: none; /* Make it push flex items to the center */
    }
    > .color_ + .color_ {
      margin-top: 0;
      /* margin-left: 2rem; */
    }
    .arrow_tabs{
      display: none;
    }
  }
`
const Color = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  img{
    width: 4.5rem;
    height: 4.5rem;
    border-radius:50% ;
  }
  span{
    background-color: ${props => props.color} ;
    width: 4.7rem;
    height: 4.7rem;
    border-radius:50% ;

  }
  .selected{
    /* border: 1.5px solid #FFFFFF;
    padding: 1.2px; */
    /* border: 1.5px solid #C8FF01; */
    box-shadow: 0 0 9px -0.5px #c8ff01;


    /* filter: blur(4px); */
  }
  .disable{
    cursor: not-allowed;
  }
  div{
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    margin-top:7px ;

  }

  @media (max-width: 575px) {
    img{
      width: 33px;
      height: 33px;
      min-width: 33px;
      min-height: 33px;
    }
    flex: 0 0 calc(24% - 2rem);
    max-width: calc(24% - 2rem);
    div{
      font-size: 100%;
    }
  }
`

const TextureWrapper = styled.div`
  background: #302A2A;
  height: 100%;
  width: 12rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  position: relative;
  animation: fadeInFromNone 0.5s ease-out;

  .arrow_tabs{
    margin : 0 !important;
    left: 0;
    right: initial !important;
    transform: translate(0 , -50%) !important;
  }
  > * + * {
    margin-top: 2rem;
  }
  @media (max-width: 575px) {
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    flex-direction: row;
    width: auto;
    background: transparent;
    padding: 1.3rem 2rem 1.3rem 2rem;
    justify-content: flex-start;
    gap: 1rem;

    > * + * {
      margin-top: 0;
    }
    .arrow_tabs{
      display: none;
    }
  }
`
export default function Options(props) {
  // console.log(props.model_params_config_part_selected)
  return (
    <React.Fragment>
      <OptionsWrap id='OptionsWrap'>
        {
        commonOptions.map((value, key) =>(
          <div
            onClick={ () => props.onSelectColorByPart( { color_type : key, color_type_defult_selected : false } ) }
            className={`${ key == props.config_selected.color_type ? 'selected' : '' }`}
            key={key}
          >{value.name}</div>
        ))
        }
        { props.config_selected.color_type != null && <img className="arrow_tabs" src={arrow_424242} alt="arrow_424242" /> }
      </OptionsWrap>


      {/* boje */}
    {
      !props.config_selected.color_type_defult_selected &&
      <React.Fragment>
        <ColorWrapper id='ColorWrapper'>
          {
            commonOptions[props.config_selected.color_type].colors.map((value, key) =>(
              <Color
                onClick={ () =>
                  props.setMaterialColor(
                    { color : key, color_hex : value.color_hex, color_type_key: commonOptions[props.config_selected.color_type].key, price: value.price },
                    {
                      material: props.model_params_config_part_selected.materials[props.config_selected.color_texture_key],
                      parts: props.model_params_config_part_selected.parts,
                      color_hex: value.color_hex
                    }
                  )
                }
                key={key}
                className={'color_'}
                // color={value.color_hex}
              >
                {/* <span className={`${ key == props.config_selected.color ? 'selected': ''}`}  ></span> */}
                <img
                  className={`${
                    key == props.config_selected.color && props.config_selected.color_type_key == commonOptions[props.config_selected.color_type].key
                    ? 'selected': ''
                  }`}
                  src={value.icon}
                  alt="value.name"
                />
                <div>{ value.name }</div>
              </Color>
            ))
          }
        </ColorWrapper>
        <TextureWrapper id='TextureWrapper'>
          {
            ColorTexture.map((value, key) =>(
              <Color
                onClick={ () =>{
                  if(props.config_selected.color_type_key != commonOptions[props.config_selected.color_type].key) return
                  props.setMaterialTexture(
                    { color_texture : key, color_texture_key : value.key  },
                    {
                      material: props.model_params_config_part_selected.materials[value.key],
                      parts: props.model_params_config_part_selected.parts,
                      color_hex: props.config_selected.color_hex
                    }
                  )
                }}
                key={key}
              >
                {/* <span className={`${ key == props.config_selected.color ? 'selected': ''}`}  ></span> */}
                <img
                  className={`${ key == props.config_selected.color_texture ? 'selected': ''} ${props.config_selected.color_type_key != commonOptions[props.config_selected.color_type].key ? 'disable' : ''}`}
                  src={value.icon}
                  alt="value.name"
                  />
                <div>{ value.name }</div>
              </Color>
            ))
          }
          <img className="arrow_tabs" src={arrow_828282} alt="arrow_828282" />
        </TextureWrapper>
      </React.Fragment>
    }
    </React.Fragment>
  )
}

Options.propTypes = {
  onSelectColorByPart : PropTypes.func,
  config_selected : PropTypes.object,
  setMaterialColor : PropTypes.func,
  setMaterialTexture : PropTypes.func,
  model_params_config_part_selected: PropTypes.object,
}
