/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { copy_icon, email, green_close, whatsapp } from '../../Helpers/ImageImport';

const MainWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 13rem;
  transform: translate(0, -50%);
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 45px -7px rgba(200, 255, 1, 0.6);
  border-radius: 16px;
  padding: 3rem;
  max-width: 100%;
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img{
      height: 3.5rem;
      margin-bottom: 1rem;
    }
    .button{
      /* margin: 0 2rem; */
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;

      text-align: center;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      text-decoration: none;
      color: #ffffff;

    }
  }

  @media (max-width: 575px) {
    left: 50%;
    right: initial;
    transform: translate(-50%, -50%);

  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`
const CloseIMG = styled.img`
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
`

export default function ShereBox(props) {
  let wa_link ='', text = 'shere text'

  if(checkDevice() == 'desktop'){

    wa_link = `https://web.whatsapp.com://send?text= https://www.youtube.com/watch?v=ohpCMpderow`;
  } else{
    wa_link = `whatsapp://send?text=This is WhatsApp sharing example using link`;
  }
  const click_ = (wa_flag) =>{
    navigator.clipboard.writeText(text).then(function() {
      if(wa_flag){
        window.open(wa_link, '_blank');
      }
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      if(wa_flag){
        window.open(wa_link, '_blank');
      }
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <MainWrapper>
      <CloseIMG onClick={() => props.setShereFlag()} src={green_close} alt="Close" />
      <Title>share your<br/> configuration</Title>
      <div className="row">
        <div className='button' onClick={() => click_(true)}  data-action="share/whatsapp/share">
          <img src={whatsapp} alt="whatsapp" />
          Whatsapp
        </div>
        <a  className='button' href="mailto:abc@example.com?subject=Feedback&body=Message">
          <img src={email} alt="email" />
          Email
        </a>
        <div className='button' onClick={() => click_(false)}>
          <img src={copy_icon} alt="copy_icon" />
          Copy Link
        </div>
      </div>
    </MainWrapper>
  )
}
ShereBox.propTypes = {
  setShereFlag : PropTypes.func,
}
