/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setConfigOptions, setConfigPartSelected, setConfigOptionsFinale } from '../Store/Actions/ConfigActions';

import Sidebar from './Sidebar';

class ConfigModel extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  setSelectedPart = (value, transition_name) =>{
    Unlimited3D.activateTransition({transition: value == this.props.config.config_part_selected ? 'zoom_out' : transition_name }, () =>{
    });
    this.props.setConfigPartSelected(value == this.props.config.config_part_selected ? null: value);
  }
  onSelectColorByPart = (object_keys_values, callback = () =>{}) =>{
    // console.log(object_keys_values)
    this.props.setConfigOptions({
      key_part : this.props.config.config_part_selected,
      values : object_keys_values
    }).then(() => callback());
  }
  setMaterialColor = (object_keys_values, {material, parts, color_hex}) =>{
    this.onSelectColorByPart(object_keys_values, () =>{
      Unlimited3D.changeMaterial( { parts : parts, material : material }, (e1) =>{ console.log(e1)
        Unlimited3D.changeMaterialColor( {material : material, color: color_hex }, () => {
          this.props.setConfigOptionsFinale(this.props.config.options)
        });
      });
    })
  }
  setMaterialTexture = (object_keys_values, {material, parts, color_hex}) =>{
    this.onSelectColorByPart(object_keys_values, () =>{
      if(color_hex != null){
        Unlimited3D.changeMaterial( { parts : parts, material : material }, (e1) =>{ console.log(e1)
          Unlimited3D.changeMaterialColor( {material : material, color: color_hex }, () => {
            this.props.setConfigOptionsFinale(this.props.config.options)
          });
        });
      }
    })
  }

  render() {

    return (
      <React.Fragment>
        <Sidebar
          config_part_selected={this.props.config.config_part_selected}
          config_selected={this.props.config.options[this.props.config.config_part_selected]}
          setSelectedPart={this.setSelectedPart}
          selected_model={this.props.selected_model}
          onSelectColorByPart={this.onSelectColorByPart}
          setMaterialColor={this.setMaterialColor}
          setMaterialTexture={this.setMaterialTexture}
          model_params={this.props.model_params}
        />
      </React.Fragment>
    )
  }
}

ConfigModel.propTypes = {
  model_params : PropTypes.object.isRequired,
  selected_model: PropTypes.number,
  setConfigOptions : PropTypes.func.isRequired,
  setConfigOptionsFinale : PropTypes.func.isRequired,
  setConfigPartSelected : PropTypes.func.isRequired,
  config : PropTypes.object.isRequired,

}

const mapStateToProps = (state) => ({
  config : state.config,
});
export default connect(mapStateToProps, { setConfigOptions, setConfigPartSelected, setConfigOptionsFinale })(ConfigModel);
