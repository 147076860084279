import React from 'react';
import styled from 'styled-components';
import { atom, logo, LogoText, origin } from '../Helpers/ImageImport';
import PropTypes from 'prop-types';

const MainWrapp = styled.div`
  height:100% ;

`
const FirstScreen = styled.div`
  height:100% ;
  display: flex ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button{
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 2.1rem;

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding:19px 44px ;

    background: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    cursor: pointer;
    margin-top: 10rem;
    margin-bottom: 6.5rem;
  }
  .desc{
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
  .button::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:25px;
    padding:3px;
    background:linear-gradient(90deg, #5BB95E 0%, #ABD026 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  .text_decoration {
    margin-top: 6px;
    text-decoration: underline;
    cursor: pointer;
  }
`
const SecendScreen = styled.div`
  position: relative;
  height:100% ;
  display: flex ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo{
    position: absolute;
    top: 7.3rem;
  }
  .title{
    font-style: italic;
    font-weight: 500;
    font-size: 2.6rem;

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
  .desc{
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
  .wrap{
    margin-top:5rem ;
    display:flex ;
    div{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0 8rem ;

      @media (max-width: 575px) {
        margin: 0 auto ;
        img{
          /* width: 80%; */
          height: 450px;
          max-height: 100%;
        }
      }
    }
    .model_wrap{
      @media (max-width: 575px) {
        margin: 0 1rem ;
      }
    }

  }
`
const Choosespeeker = (props) => {
  const [screen_flag, setScreenFlag] = React.useState(true);

  return (
    <MainWrapp>
      {
        screen_flag ?
        <FirstScreen>
          <img src={LogoText} alt="LogoText" />
          <div onClick={() => setScreenFlag(false)} className="button">Choose Your Speaker</div>
          {/* <div className="desc">Starts from  €14,900</div> */}
          <div className="desc text_decoration" >Find out more</div>
        </FirstScreen>
        :
        <SecendScreen>
          <img className='logo' src={logo} alt="logo" />
          <div className="wrap">
            <div className="model_wrap" onClick={() => props.setSelectedModel(0)}>
              <img src={origin} alt="origin" />
              <div className='title'>
                ORIGIN
                <div className="desc">Starts from €9.990</div>
              </div>
            </div>
            <div className="model_wrap" onClick={() => props.setSelectedModel(1)}>
              <img src={atom} alt="atom" />
              <div className='title'>
                ATOM
                <div className="desc">Starts from €4.998</div>
              </div>
            </div>
          </div>
        </SecendScreen>
      }
    </MainWrapp>
  );
}

Choosespeeker.propTypes = {
  setSelectedModel : PropTypes.func,
}

export default Choosespeeker;
