/* eslint-disable no-return-assign */
import initialState from '../initialState';
import {
  SET_SELECTED_RIGHTBAR,
} from '../../Constants/storeActionTypes';


export default function (state = initialState, action) {
  // let newState = {};
  switch (action.type) {
    case SET_SELECTED_RIGHTBAR:
      return Object.assign({}, state, {
        selected_rightbar: action.payload
      })

    default:
      return state;
  }

}


